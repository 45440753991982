@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

@import 'Includes/_custom-bootstrap';
@import "Includes/_fonts";
@import 'Includes/common';

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root{
  height: 100vh;
}
.container-fluid{
  height: 100vh;
}

.content-block{
  height: 100vh;
}

body{
  position: relative;
}

.wait-block{
  display: flex;
  justify-content: center;
  .profile-data-spinner{
    width: 50px;
    height: 50px;
  }
}

.loader-full-block{
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  z-index:1500;
  .loader-inner{
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: #fff;
    z-index:1600;
  }
  .loader-img{
    z-index:1700;
    top: 45vh;
  }
}

.base-btn-pink-loader.btn-primary,
.base-btn-pink.btn-primary{
  background-color: #CB0853 !important;
  border-color: #CB0853 !important;
  border-radius: 25px !important;
  font-size: 14px !important;
  line-height: 1.1em !important;
  font-family: "ageo-bold", sans-serif !important;
}
