@import '../Includes/_conf';

.header-block{
    background-color: $headerColor;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 5px 0 $lightGreyColor;
    margin-bottom: 5px;
    .header-logo{
        width: 250px;
    }
    .header-title{
        @include AgeoBold;
        font-size: 20px;
        color:$magentaColor;
        border-left: solid 2px $headerPinkColor;
        height:45px;
        line-height: 45px;
        padding-left:45px;
    }
    svg {
        height: 90px;
        transform: scale(1.5);
        pointer-events: none;
        fill: $pinkHeavyColor;
    }
    .icon-block{
        flex-direction: row;
        align-items: center;
    }
    .btn-icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width:35px;
        height:35px;
        background-color: #fff;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 1px 3px lightgray;
        a{
            color: red;
        }
    }
    .text-icon{
        @media(min-width: 576px){
            line-height: 1.1em;
        }
        line-height: 1em;
        color: $pinkHeavyColor;
        @include AgeoBold;
    }
}