@import '../Includes/conf';

button,
button:active,
button:focus,
button:hover,
.btn,
.btn:active,
.btn:focus,
.btn:hover{
  outline:none !important;
}

.base-btn-pink-loader,
.base-btn-pink {
  &.btn-primary{
    background-color: $pinkColor;
  }
  background-color: $pinkColor;
  border-color: $pinkColor;
  border-radius: 25px;
  font-size: 14px;
  line-height: 1.1em;
  @include AgeoBold;
  &:active,
  &:focus,
  &:disabled,
  &:hover {
    background-color: $pinkColor !important;
    border-color: $pinkColor !important;
    box-shadow: $pinkColor !important;
  }
}

.base-btn-pink-loader{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}


@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}