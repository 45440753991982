@import "../Includes/_conf";

.text-link{
  @include AgeoMedium;
  font-size: 18px;
  color: $pinkColor;
  text-decoration: underline;
  text-transform: uppercase;
  cursor:pointer;
}

.close{
  color: $pinkColor;
}

.btn-question{
  button{

  }
  .text-uppercase{
    text-transform: none !important;
  }
}

.sos-modal-dialog{
  .modal-dialog{
    max-width: 600px;
  }
  .modal-header{
    border-bottom: none;
  }
  .title{
    @include AgeoBold;
    font-size: 24px;
    color: $pinkColor;
  }
  .text-dialog{
    p{
      @include AgeoMedium;
      font-size: 16px;
    }
  }
}

.sos-modal-form{
  .modal-dialog{
    max-width: 800px;
  }
  .modal-header{
    border-bottom: none;
  }
  .text-pink{
    color: $pinkColor;
  }
  .text-bold{
    font-size: 18px;
  }
  .header{
    svg{
      //transform: scale(5.0);
      width:80px;
      height: 80px;
      fill: $pinkHeavyColor;
    }
    .title{
      p{
        @include AgeoExtraBold;
        font-size: 24px;
      }
    }
    .text-header-result{
      p{
        @include AgeoMedium;
        font-size: 15px;
      }
    }
    p{
      @include AgeoRegular;
      font-size: 14px;
    }
  }
  .q-row{
    border: solid 1px $headerPinkColor;
    .title{
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      @include AgeoBold;
      text-transform: uppercase;
      color: $purpleColor;
      font-size: 18px;
      width: 40%;
      background-color: $blockWhite;
      z-index: 1000;
    }
    .text-question{
      @include AgeoBold;
      font-size: 16px;
    }
    .base-btn-pink{
      width: 130px;
      color: $blockWhite;
      border: solid 1px $blackColor;
      &.no-selected{
        background-color: $blockWhite;
        color: $blackColor;
        border-color: $blackColor;
        &:hover{
          color: $blockWhite;
          border-color: $pinkColor;
        }
      }
    }
    .desc-question{
      p{
        @include AgeoRegular;
        font-size: 14px;
      }
    }
  }
  .footer{
    a{
      color: $pinkColor;
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
  .btn-row{
    .text-circle{
      @include AgeoRegular;
      font-size: 14px;
    }
    .block-btn{
      cursor:pointer;
      &:hover{
        .btn-circle{
          border-color: $pinkColor;
          background-color: $pinkColor;
          color: $blockWhite;
        }
      }
    }
    .btn-circle{
      border: solid 2px $blackColor;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding-top: 3px;
      @include AgeoBold;
      font-size: 15px;
      &.selected{
        border-color: $pinkColor;
        background-color: $pinkColor;
        color: $blockWhite;
      }
    }
  }
  .emotion-row{
    .card{
      width:130px;
      height:80px;
      border-radius: 10px;
      box-shadow: 0 3px 5px 0 $lightGreyColor;
      cursor: pointer;
      &.selected,
      &:hover{
        background-color: $pinkColor;
        color: $blockWhite;
        box-shadow: 0 3px 5px 0 $pinkColor;
        .icon{
          stroke: $whiteColor;
          fill: $whiteColor;
        }
      }
    }
    .wrap-text{
      //@include AgeoMedium;
      font-size: 14px;
      font-weight: 700;
    }
    svg{
      width: 30px;
      height: 30px;
      stroke: $pinkColor;
    }
  }
  .context-row{
    .text-context{
      @include AgeoMedium;
      font-size: 14px;
      line-height: 1em;
    }
    .context-block{
      cursor:pointer;
    }
    .check-case{
      width: 20px;
      height: 20px;
      border: solid 1px $lightGreyColor;
      border-radius: 5px;
      box-shadow: 1px 1px 1px 1px $lightGreyColor;
      img{
        width: 100%;
      }
    }
  }
  .result-row{
    .block-result{
      border: solid 1px $lightGreyColor;
      border-radius: 10px;
      box-shadow: 0 1px 1px 0 $lightGreyColor;
    }
    .title{
      @include AgeoBold;
      font-size: 18px;
    }
    .text-result{
      @include AgeoRegular;
      font-size: 14px;
    }
    ul{
      padding-left: 15px;
    }
  }
}