@font-face {
  font-family: 'ageo';
  src: local('ageo'), url(../../fonts/ageo/Ageo.otf) format('opentype');
}

@font-face {
  font-family: 'ageo-bold';
  src: local('ageo-bold'), url(../../fonts/ageo/Ageo-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'ageo-heavy';
  src: local('ageo-heavy'), url(../../fonts/ageo/Ageo-Heavy.otf) format('opentype');
}

@font-face {
  font-family: 'ageo-medium';
  src: local('ageo-medium'), url(../../fonts/ageo/Ageo-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'ageo-light';
  src: local('ageo-light'), url(../../fonts/ageo/Ageo-Light.otf) format('opentype');
}

@font-face {
  font-family: 'ageo-semi-bold';
  src: local('ageo-semi-bold'), url(../../fonts/ageo/Ageo-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'ageo-extra-bold';
  src: local('ageo-extra-bold'), url(../../fonts/ageo/Ageo-ExtraBold.otf) format('opentype');
}

@font-face {
  font-family: 'herbanium';
  src: local('herbanium'), url(../../fonts/herbarium/Herbarium.otf) format('opentype');
}