@import "../../../../../node_modules/rfs/scss";

@mixin textLato($weight, $size, $line, $color, $opacity) {
    font-family: "Lato", sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $line;
    color: $color;
    opacity: $opacity;
}

@mixin textMonserrat($weight, $size, $line, $color, $opacity) {
    font-family: "Montserrat", sans-serif;
    font-weight: $weight;
    font-size: $size;
    line-height: $line;
    color: $color;
    opacity: $opacity;
}

@mixin textLato-regular{
    font-family: "Lato", sans-serif;
}

@mixin AgeoRegular{
    font-family: "ageo", sans-serif;
}

@mixin AgeoBold{
    font-family: "ageo-bold", sans-serif;
}

@mixin AgeoHeavy{
    font-family: "ageo-heavy", sans-serif;
}

@mixin AgeoSemiBold{
    font-family: "ageo-semi-bold", sans-serif;
}

@mixin AgeoLight{
    font-family: "ageo-light", sans-serif;
}

@mixin AgeoExtraBold{
    font-family: "ageo-extra-bold", sans-serif;
}

@mixin AgeoMedium{
    font-family: "ageo-medium", sans-serif;
}

@mixin Herbarium{
    font-family: "herbanium", sans-serif;
}

@mixin noOutline{
    cursor: pointer;
    &:visited,
    &:active,
    &:hover,
    &:focus-visible,
    &:focus-within,
    &:focus{
        border: none !important;
        outline: none !important;
    }
}

$purpleColor: #4f205d;
$magentaColor: #642a73;
$lightGreyColor: #d2c8c8;
$greyColor: #efefef;
$midGreyColor: #756969;
$whiteColor: #ffffff;
$pinkColor: #CB0853;
$blackColor: #000;
$errorColor: #BE0348;
$pinkHeavyColor: #be0348;
$headerColor: #F8F9FA;
$headerPinkColor: #E11259;
$placeHolderColor: #817E7E;
$blockWhite: #ffffff;
$blockBackground: #F1F1F1;

.whiteColor{
    color: $whiteColor;
}

.magentaColor{
    color: $magentaColor;
}

.purpleColor{
    color: $purpleColor;
}

.midGreyColor{
    color: $midGreyColor;
}

.size20{
    @include font-size(20px);
}

.size25{
    @include font-size(25px);
}

.size30{
    @include font-size(30px);
}

.size35{
    @include font-size(35px);
}

.size40{
    @include font-size(40px);
}


@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}