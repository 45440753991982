@import '../Includes/conf';

button,
button:active,
button:focus,
button:hover,
.btn,
.btn:active,
.btn:focus,
.btn:hover{
  outline:none !important;
}

.base-btn-magenta{
  background-color: $magentaColor;
  border-color: $magentaColor;
  border-radius: 25px;
  font-size: 14px;
  line-height: 1.1em;
  &:active,
  &:focus,
  &:hover{
    background-color: $magentaColor !important;
    border-color: $magentaColor !important;
    box-shadow: $magentaColor !important;
  }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}